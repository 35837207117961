'use strict';
import xlsx from "xlsx";

class Xlsreport {
  constructor(title, period, curs, reportname, publisher) {
    this.title = title;
    this.publisher = publisher;
    this.period = period;
    this.data = []
    this.head = []
    this.reportname = reportname
  }
  setBody(body) {
    this.data = body
  }
  setHeader(head) {
    this.head = head
  }
  BuildReport() {
    const wb = xlsx.utils.book_new();
    let wscols = [
      { wch: 10 },
      { wch: 35 },
      { wch: 20 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
      { wch: 35 },
    ];

    const ws = xlsx.utils.aoa_to_sheet(this.data);
    ws["!cols"] = wscols;

    wb.Props = {
      Title: this.title,
      Subject: "Report",
      Author: "Digital GAM",
      CreatedDate: new Date(),
    };
    let name = this.title.substr(0, 31)
    xlsx.utils.book_append_sheet(wb, ws, name);
    xlsx.writeFile(wb, "REPORT_" + name + ".xlsx");

  }
}

export default Xlsreport;